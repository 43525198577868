import React from "react"
// import Slider from "react-slick"
import loadable from "@loadable/component"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { Link } from "gatsby"
import H3 from "../../core/H3"
import H2 from "../../core/H2"
import P from "../../core/P"
import { addsLinks } from "../../../util/conditionalLinks"
const Slider = loadable(() => import("react-slick"))

const settings = {
  className: "center",
  centerMode: true,
  variableWidth: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  focusOnSelect: true,
  arrows: false,
  resumeOnVisible: true,
  useTransform: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        className: "",
        centerMode: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        className: "",
        centerMode: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        className: "",
        centerMode: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  // prevArrow: <button type="button" className="slick-prev text-shark-500">&nbsp;</button>,
  // nextArrow: <button type="button" className="slick-next text-shark-500">&nbsp;</button>,
}

export const WorkSlider = ({ WorkPortfolioSlider, theme }) => {
  const [current, setCurrent] = React.useState()
  const { title, description, portfolio } = WorkPortfolioSlider
  React.useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])

  return (
    <div>
      <div
        className="w-full py-16 flex flex-col justify-center place-items-center"
        data-aos="fade-up"
      >
        {title && <H2 title={title} className="max-w-4xl text-center mx-auto" />}
        {title && theme && (
          <hr
            className="h-1 w-40 mt-5 border-0"
            style={{ backgroundColor: theme }}
          />
        )}
        {description && (
          <P
            title={description}
            className="max-w-4xl text-center mx-auto text-shark-400"
          />
        )}
      </div>
      <div className="slider-container w-full" data-aos="fade-up">
        <div className="bg-transperent overflow-hidden">
          <Slider
            {...settings}
            className="custom-slick-slider slider slider__container mx-auto relative"
          >
            {portfolio && (portfolio.map(
              ({
                title,
                projectFeatureTag,
                projectCountryTag,
                WorkCPT: {
                  coverImage,
                  tagline,
                  textAlign,
                  android,
                  ios,
                  caseStudy,
                  website,
                },
              }) => (
                <div
                  key={Math.random()}
                  className="flex justify-center overflow-hidden relative rounded lg:p-6 p-3"
                >
                  <div className="h-full bg-black block z-10 flex-shrink-0">
                    <img
                      className="h-full object-cover z-0 lg:opacity-90 md:opacity-40 opacity-40 lg:w-auto md:w-full w-full"
                      style={{ maxHeight: "500px" }}
                      src={coverImage?.mediaItemUrl}
                      width={coverImage?.mediaDetails?.width}
                      height={coverImage?.mediaDetails?.height}
                      alt={
                        coverImage?.altText
                          ? coverImage?.altText
                          : "Work Portfolio"
                      }
                      // title={coverImage?.altText}
                      loading="lazy"
                    />
                  </div>
                  <div className="w-full">
                    <div
                      className={`flex items-center lg:bg-transparent text-center absolute top-0  h-full w-full lg:w-1/2 lg:p-6 p-3  ${textAlign === "right" ? "right-0" : "left-0"
                        } text-white`}
                    >
                      <div className="flex-1 px-0 lg:p-0">
                        <div className="flex items-center gap-2 justify-center ">
                        {title && <H3 title={title} className="text-white" />}
                        </div>
                        <div
                          className="text-sm mt-2"
                          dangerouslySetInnerHTML={{ __html: tagline }}
                        />
                        <p className="mt-4 lg:mt-8 lg:block md:block hidden">
                          {projectFeatureTag?.nodes?.map(({ name }) => (
                            <button
                              key={Math.random()}
                              className="rounded text-xs m-1 inline-flex items-center font-normal leading-sm px-4 py-2 bg-transparent  bg-white bg-opacity-10 text-white"
                            >
                              {name}
                            </button>
                          ))}
                        </p>
                        <div className="mt-3 flex justify-center flex-row">
                          {ios ? (
                            <div className="mt-2 mr-4">
                              <a
                                href={ios.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img
                                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/appstore.png"
                                  alt="Apple"
                                  // title="Apple"
                                  width="64px"
                                  height="64px"
                                  className="h-5 w-5"
                                  loading="lazy"
                                />
                              </a>
                            </div>
                          ) : null}

                          {android ? (
                            <div className="mt-2 mr-4">
                              <a
                                href={android.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img
                                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/playstore.png"
                                  alt="Android"
                                  width="64px"
                                  height="64px"
                                  // title="Android"
                                  className="h-5 w-5"
                                  loading="lazy"
                                />
                              </a>
                            </div>
                          ) : null}

                          {website ? (
                            <div className="mt-2 mr-4">
                              <a
                                href={website.url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <img
                                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/web.png"
                                  alt="Web"
                                  // title="Web"
                                  width="64px"
                                  height="64px"
                                  className="h-5 w-5"
                                  loading="lazy"
                                />
                              </a>
                            </div>
                          ) : null}
                          {caseStudy &&
                            (current && addsLinks.includes(current) ? (
                              <div className="mt-2 mr-4">
                                <a
                                  href={caseStudy.url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  className=""
                                >
                                  <img
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/arrow.png"
                                    alt="Full case study"
                                    // title="Full case study"
                                    className="h-5 w-5"
                                    width="64px"
                                    height="64px"
                                    loading="lazy"
                                  />
                                </a>
                              </div>
                            ) : (
                              <div className="mt-2 mr-4">
                                <Link to={caseStudy.url} className="">
                                  <img
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/arrow.png"
                                    alt="Full case study"
                                    // title="Full case study"
                                    className="h-5 w-5"
                                    width="64px"
                                    height="64px"
                                    loading="lazy"
                                  />
                                </Link>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
export default WorkSlider
